const popupContent = {
    textAlign: "center",
    height: "200px",
    width: "250px",
    marginTop: "20px",
    marginBottom: "20px",
};
const popupHead = {
    fontWeight: "bold",
    fontSize: "22px",
};

const popupText = {
    fontSize: "15px",
    marginBottom: "50px",
};

const okText = {
    fontSize: "30px",
};

export { popupContent, popupHead, popupText, okText };
