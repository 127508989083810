import React from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import InfoContext from "components/InfoContext";

import pointsOfInterest from "data/points.json";
import { attachPopup, getOrangeIcon } from "./pointsHelper";

const AllPoints = ({ pointFilter }) => {
    /* eslint-disable no-unused-vars */
    const { info, setInfo } = React.useContext(InfoContext);
    const map = useMap();

    React.useEffect(() => {
        if (!map) return;
        const orangeIcon = getOrangeIcon();
        const sleeps = new L.GeoJSON(pointsOfInterest, {
            filter: (feature, layer) => {
                return feature.properties.Categories?.sleeps;
            },
            pointToLayer: (feature, latlng) =>
                L.marker(latlng, { icon: orangeIcon }),
            onEachFeature: attachPopup,
        });

        const eats = new L.GeoJSON(pointsOfInterest, {
            filter: (feature, layer) => {
                return feature.properties.Categories?.eats;
            },
            pointToLayer: (feature, latlng) =>
                L.marker(latlng, { icon: orangeIcon }),
            onEachFeature: attachPopup,
        });

        const other = new L.GeoJSON(pointsOfInterest, {
            filter: (feature, layer) => {
                return !(
                    feature.properties.Categories?.eats ||
                    feature.properties.Categories?.sleeps
                );
            },
            pointToLayer: (feature, latlng) =>
                L.marker(latlng, { icon: orangeIcon }),
            onEachFeature: attachPopup,
        });

        eats.on("click", (event) => {
            var clickedMarker = event.layer;
            setInfo(clickedMarker.feature.properties);
        });

        sleeps.on("click", (event) => {
            var clickedMarker = event.layer;
            setInfo(clickedMarker.feature.properties);
        });

        other.on("click", (event) => {
            var clickedMarker = event.layer;
            setInfo(clickedMarker.feature.properties);
        });

        L.control
            .layers(
                null,
                {
                    Restaurants: eats.addTo(map),
                    Lodging: sleeps.addTo(map),
                    Other: other.addTo(map),
                },
                { collapsed: false }
            )
            .addTo(map);
    }, [map, setInfo]);

    return null;
};

export default AllPoints;
