import React from "react";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { FaWineGlassAlt, FaBed, FaBeer } from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";

import {
    popupContent,
    okText,
} from "assets/stylesheets/popups";

export const getOrangeIcon = () => (new L.Icon({
    iconUrl:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
}));

export const attachPopup = (feature = {}, layer) => {
    const { properties = {} } = feature;
    if (!properties.Name) return;

    var content = ReactDOMServer.renderToString(
        <div style={popupContent}>
            <div style={okText}>
                <IoRestaurant />{" "}
                {properties.Categories.eats && (
                    <>
                        <FaWineGlassAlt /> <FaBeer />
                    </>
                )}
                {properties.Categories.sleeps && (
                    <>
                        {" "}
                        <FaBed />
                    </>
                )}
            </div>
            <img
                height="130px"
                width="100%"
                alt={""}
                src={`https://vgb-img.s3.amazonaws.com/featured/${properties.PlusCode.replace(
                    "+",
                    "."
                )}.webp`}
            />
        </div>
    );

    layer.bindPopup(content);
};
