import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMap } from "react-leaflet";
import { Link } from "gatsby-theme-material-ui";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { promiseToFlyTo } from "lib/map";

import AllPoints from "../components/AllPoints";
import InfoContext from "../components/InfoContext";
import Layout from "../components/Layout";
import Map from "../components/Map";
import Showcase from "../components/Showcase";

const LOCATION = {
    lat: 32.03903750699227,
    lng: -116.65275227586649,
};

const CENTER = [36.41014329137713, -120.12816440374559];
const DEFAULT_ZOOM = 5;
const ZOOM = 12;

const timeToZoom = 1000;

const MapEffect = ({ markerRef }) => {
    const map = useMap();
    map.attributionControl._attributions = {};
    map.attributionControl.addAttribution("valleguadalupebaja.com");
    useEffect(() => {
        if (!map) return;

        (async () => {
            const location = LOCATION;

            setTimeout(async () => {
                await promiseToFlyTo(map, {
                    zoom: ZOOM,
                    center: location,
                });
            }, timeToZoom);
        })();
    }, [map, markerRef]);

    return null;
};

const BrowsePage = () => {
    const markerRef = useRef();
    const [info, setInfo] = useState(null);

    const mapSettings = {
        style: { height: 480 },
        center: CENTER,
        defaultBaseMap: "OpenStreetMap",
        zoom: DEFAULT_ZOOM,
    };

    return (
        <Layout pageName="browse">
            <Helmet>
                <title>Locations</title>
            </Helmet>
            <Container maxWidth="md">
                <Box>
                    <Typography
                        align="center"
                        variant="body2"
                        color="text.secondary"
                    >
                        Select a marker to view venue details.
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        align="center"
                        variant="body2"
                        color="text.secondary"
                    >
                        Also visit our <Link to="/gallery/">Locations</Link>{" "}
                        gallery.
                    </Typography>
                </Box>
                <InfoContext.Provider value={{ info, setInfo }}>
                    <Map {...mapSettings}>
                        <MapEffect markerRef={markerRef} />
                        <AllPoints />
                    </Map>
                    <Showcase info={info} />
                </InfoContext.Provider>
            </Container>
        </Layout>
    );
};

export default BrowsePage;
